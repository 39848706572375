nav {
    max-width: 1150px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}

div.header {
    border-bottom: 10px solid #ff9300;
    margin-top: 30px;
    background-color: #fff;
}

div.logo-region {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-left: 7px;
}

div.logo-region h2 {
    padding-bottom: 5px;
    font-weight: 500;
    font-size: 28px;
}

nav .hero--logo {
    max-height: 30px;
    margin-top: 3px;
}