body {
    background-color: #fafafa;
}

.error-message {
    font-size: .6em;
    line-height: .6em;
   
    color: red !important;
}

#type-group {
    color: #464646;
}
.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label {
    color: #464646;
    font-size: 15px;;
}

#char-count {
    text-align: right;
    font-size: 10px;
    margin-bottom: 2em;
}
.preview-image {
    max-width: 650px;
    padding-left: 50px;
}