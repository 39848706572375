
body {
    background-color: #fafafa;
}

.small-logo {
    max-width: 40px;
    cursor: pointer;
}

.filter h6 {
    color: #464646;
}

/*
.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary {
    color: #ff9300;
    text-transform: none;
    font-family: trasandina,sans-serif;
    font-weight: 500;
    font-size: 1.1rem;
    line-height: 1.75;
    
}
.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.Mui-disabled {
    color: rgba(0, 0, 0, 0.26);
}


.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary:hover {
    background-color: rgba(255, 147, 0, 0.04);
}

.MuiDialogTitle-root.MuiTypography-h6 {
    color: #ff9300;
    font-family: trasandina,sans-serif;
}

.MuiDialogContent-root .MuiTypography-h5 {
    font-family: trasandina,sans-serif;
}*/