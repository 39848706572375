

.select-icon {
  width: 20px;
  margin-right: 10px;
}
.search-icon {
  width: 20px;
}

.checkbox-label .MuiFormControlLabel-label {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 1.5em;
  color: #000000 !important;
  font-style: italic;
}

.menu-item-row--brand-thumbnail, .menu-item-row--flag-thumbnail {
  width: 35px;
  align-self: center;
}
.menu-item-row--menu-thumbnail {
  object-fit: cover;
  object-position: center;
  height: 140px;
  width: 215px;
  margin-right: 30px;
}

#country {
  display: flex;
  align-items: center;
}
.date-picker {
  max-width: 200px;
}
.date-picker .MuiSvgIcon-root {
  width: 20px;
}
.filters-bar {
  justify-content: center;
  border-bottom: 2px solid #ff9300;
}
/* clears the ‘X’ from Internet Explorer */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
#pagination {
  order: 1;
}

.ais-Hits-list {
    list-style-type: none;
}

.ais-Pagination {
  width: 100%;
}

.ais-Pagination-list {
  display: flex;
  margin: 0 0 16px;
  padding: 0;
  background: none;
  list-style: none;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  box-shadow: none;
  flex-wrap: wrap;
}

.ais-Pagination-link {
  display: block;
}

.ais-Pagination-item,
.ais-Pagination-item--disabled {
  flex: 0 0 40px;
  width: auto;
  height: auto;
  margin: 4px;
  background-color: #a0c800;
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
  text-align: center;
  border-radius: 4px;
  transition: all 0.1s linear;
}

.ais-Pagination-item--disabled {
  display: none;
}

.ais-Pagination-item:hover,
.ais-Pagination-item--selected {
  background-color: #ff9300;
}

.ais-Pagination-item .ais-Pagination-link,
.ais-Pagination-item--disabled .ais-Pagination-link {
  text-decoration: none;
  color: #fff;
  line-height: 32px;
}

.conceptName {
  text-decoration: none;
  color: #ff9300;
}
.conceptName:hover {
  text-decoration: underline;
}