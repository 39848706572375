
* { padding: 0; margin: 0; }
html, body {
    min-height: 100% !important;
    height: 100%;
}
.draggable-item {
    height: 50px;
    line-height: 50px;
    text-align: center;
    display: block;
    background-color: #fff;
    outline: 0;
    border: 1px solid rgba(0,0,0,.125);
    margin-bottom: 2px;
    margin-top: 2px;
}
.smooth-dnd-ghost {
    display: none !important;
}

.drag-handle:active {
    cursor: url("../images/drag_handle.svg") 16 16, pointer;
}